// Cambio el fondo de obtenerInformacion y el tamaño de la imagen al hacer click en el botón
document
  .getElementById("obtenerInformacion")
  .addEventListener("click", function () {
    //Hago toggle de la clase bg-blue-300
    this.classList.toggle("bg-blue-300");
    this.classList.toggle("p-1");
    this.classList.toggle("rounded-lg");

    //Dentro del botonMedir busco la etiqueta imagen y para ella cambio la clase h-10 por h-8
    this.querySelector("img").classList.toggle("h-10");
    this.querySelector("img").classList.toggle("h-8");
  });

//Creo un manejador de si paso el ratón por encima del botón obtenerInformacion
document
  .getElementById("obtenerInformacion")
  .addEventListener("mouseover", function () {
    let isDisabled = document.getElementById("obtenerInformacion").disabled;
    let alerta = "";

    //Mientras tenga el raton encima muestro la alerta
    if (!isDisabled) {
      alerta = `
    <div class="flex items-center p-4 mb-4 text-sm text-blue-800 border border-blue-300 rounded-lg bg-blue-50 role="alert">
        <svg class="flex-shrink-0 inline w-4 h-4 me-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
            <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
        </svg>
        <span class="sr-only">Info</span>
        <div>
            Para obtener información de un punto del mapa, haz click en su símbolo.
        </div>
    </div>
    `;
    } else {
      alerta = `
      <div class="flex items-center p-4 mb-4 text-sm text-red-800 rounded-lg bg-red-50" role="alert">
        <svg class="flex-shrink-0 inline w-4 h-4 me-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
          <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
        </svg>
        <span class="sr-only">Info</span>
        <div>
          Debes dejar de medir para poder usar obeter información.
        </div>
      </div>
      `;
    }

    //Muestro la alerta en el centro del eje x y a 68px del eje y
    let divAlerta = document.createElement("div");
    divAlerta.innerHTML = alerta;
    divAlerta.style.position = "absolute";
    divAlerta.style.left = "50%";
    divAlerta.style.transform = "translateX(-50%)";
    divAlerta.style.top = "68px";
    document.body.appendChild(divAlerta);

    //Borro la alerta si el ratón sale del botón

    document
      .getElementById("obtenerInformacion")
      .addEventListener("mouseout", function () {
        divAlerta.remove();
      });
  });

//Cambio el fondo del botón alternarPlano y el tamaño de la imagen al hacer click en el botón
document.getElementById("alternarPlano").addEventListener("click", function () {
  //Hago toggle de la clase bg-blue-300
  this.classList.toggle("bg-blue-300");
  this.classList.toggle("p-1");
  this.classList.toggle("rounded-lg");

  //Dentro del botonMedir busco la etiqueta imagen y para ella cambio la clase h-10 por h-8
  this.querySelector("img").classList.toggle("h-10");
  this.querySelector("img").classList.toggle("h-8");
});

//Creo un manejador de si paso el ratón por encima del botón alternarPlano
document
  .getElementById("alternarPlano")
  .addEventListener("mouseover", function () {
    //Mientras tenga el raton encima muestro la alerta
    let alerta = `
    <div class="flex items-center p-4 mb-4 text-sm text-blue-800 border border-blue-300 rounded-lg bg-blue-50 role="alert">
        <svg class="flex-shrink-0 inline w-4 h-4 me-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
            <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
        </svg>
        <span class="sr-only">Info</span>
        <div>
            Al pulsar el botón se habilita la capa de edificaciones. Para deshabilitarla, vuelve a pulsar el botón.
        </div>
    </div>
    `;

    //Muestro la alerta en el centro del eje x y a 68px del eje y
    let divAlerta = document.createElement("div");
    divAlerta.innerHTML = alerta;
    divAlerta.style.position = "absolute";
    divAlerta.style.left = "50%";
    divAlerta.style.transform = "translateX(-50%)";
    divAlerta.style.top = "68px";
    document.body.appendChild(divAlerta);

    //Borro la alerta si el ratón sale del botón

    document
      .getElementById("alternarPlano")
      .addEventListener("mouseout", function () {
        divAlerta.remove();
      });
  });

const typeSelect = document.getElementById("type");

// Manejo el estado del select medir
typeSelect.addEventListener("change", function () {
  //Si el valor es length o area, desactivo el botón de obtener información
  if (typeSelect.value === "length" || typeSelect.value === "area") {
    /* document.getElementById("obtenerInformacion").disabled = true; */

    //Comprueba si el botón está activo y si lo está, simlula un click para desactivarlo
    if (
      document
        .getElementById("obtenerInformacion")
        .classList.contains("bg-blue-300")
    ) {
      document.getElementById("obtenerInformacion").click();

      //Desactivo el botón de obtener información
      document.getElementById("obtenerInformacion").disabled = true;

      //Establezco el cursor prohibido
      document.getElementById("obtenerInformacion").style.cursor =
        "not-allowed";
    } else {
      //Desactivo el botón de obtener información
      document.getElementById("obtenerInformacion").disabled = true;

      //Establezco el cursor prohibido
      document.getElementById("obtenerInformacion").style.cursor =
        "not-allowed";
    }
  } else if (typeSelect.value === "Desactivado") {
    //Si el valor es desactivado, desactivo el botón de obtener información
    document.getElementById("obtenerInformacion").disabled = false;

    //Establezco el cursor por defecto mano
    document.getElementById("obtenerInformacion").style.cursor = "pointer";
  }
});

//Creo un manejador de si paso el ratón por encima del botón medir linea
const medirLinea = document.getElementById("medirLinea");

medirLinea.addEventListener("mouseover", function () {
  //Mientras tenga el raton encima muestro la alerta
  let alerta = `
  <div class="flex items-center p-4 mb-4 text-sm text-blue-800 border border-blue-300 rounded-lg bg-blue-50 role="alert">
      <svg class="flex-shrink-0 inline w-4 h-4 me-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
          <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
      </svg>
      <span class="sr-only">Info</span>
      <div>
          Para empezar una medición haz click en el botón y luego en el mapa.
      </div>
  </div>
  `;

  //Muestro la alerta en el centro del eje x y a 68px del eje y
  let divAlerta = document.createElement("div");
  divAlerta.innerHTML = alerta;
  divAlerta.style.position = "absolute";
  divAlerta.style.left = "50%";
  divAlerta.style.transform = "translateX(-50%)";
  divAlerta.style.top = "68px";
  document.body.appendChild(divAlerta);

  //Borro la alerta si el ratón sale del botón

  medirLinea.addEventListener("mouseout", function () {
    divAlerta.remove();
  });
});

//Creo un manejador de si paso el ratón por encima del botón medir area
const medirArea = document.getElementById("medirArea");

medirArea.addEventListener("mouseover", function () {
  let alerta = `
  <div class="flex items-center p-4 mb-4 text-sm text-blue-800 border border-blue-300 rounded-lg bg-blue-50 role="alert">
      <svg class="flex-shrink-0 inline w-4 h-4 me-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
          <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
      </svg>
      <span class="sr-only">Info</span>
      <div>
          Para empezar una medición haz click en el botón y luego en el mapa.
      </div>
  </div>
  `;
  //Muestro la alerta en el centro del eje x y a 68px del eje y
  let divAlerta = document.createElement("div");
  divAlerta.innerHTML = alerta;
  divAlerta.style.position = "absolute";
  divAlerta.style.left = "50%";
  divAlerta.style.transform = "translateX(-50%)";
  divAlerta.style.top = "68px";
  document.body.appendChild(divAlerta);

  //Borro la alerta si el ratón sale del botón

  medirArea.addEventListener("mouseout", function () {
    divAlerta.remove();
  });
});

//Creo un manejador de si paso el ratón por encima de borrar
const borrar = document.getElementById("borrar");

borrar.addEventListener("mouseover", function () {
  let alerta = `
  <div class="flex items-center p-4 mb-4 text-sm text-blue-800 border border-blue-300 rounded-lg bg-blue-50 role="alert">
      <svg class="flex-shrink-0 inline w-4 h-4 me-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
          <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
      </svg>
      <span class="sr-only">Info</span>
      <div>
          Para borrar una medición, haz click en este botón.
      </div>
  </div>
  `;

  //Muestro la alerta en el centro del eje x y a 68px del eje y
  let divAlerta = document.createElement("div");
  divAlerta.innerHTML = alerta;
  divAlerta.style.position = "absolute";
  divAlerta.style.left = "50%";
  divAlerta.style.transform = "translateX(-50%)";
  divAlerta.style.top = "68px";
  document.body.appendChild(divAlerta);

  //Borro la alerta si el ratón sale del botón

  borrar.addEventListener("mouseout", function () {
    divAlerta.remove();
  });
});
