//Añade options para el datalist de id numerosVias enviando el valor seleccionado en el datalist de id nombresVias de forma que solo se muestran los numeros de via de la via seleccionada
export default function cargaNumerosDeVia() {
  //Comprueba que la via seleccionada coincida con alguna del datalist
  const numeroVia = document.getElementById("numerosVias");
  const nombreVia = document.getElementById("valorNombreVia");
  let nombreViaCorrecto = false;
  const opcionesNombreVia = document.getElementById("nombresVias").childNodes;
  opcionesNombreVia.forEach((element) => {
    if (nombreVia.value == element.value) {
      nombreViaCorrecto = true;
    }
  });

  if (nombreViaCorrecto) {
    const xhr = new XMLHttpRequest();
    xhr.open(
      "POST",
      "./Request/enc/cargaNumerosDeViaCifrado.php"
    );
    xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
    xhr.onload = function () {
      if (xhr.status === 200) {
        const respuesta = JSON.parse(xhr.responseText);

        //Vacío el datalist antes de añadir los nuevos options
        numeroVia.innerHTML = "";

        //Añado los options al datalist
        respuesta.forEach((element) => {
          const option = document.createElement("option");
          option.value = element.numeroCalle;
          numeroVia.appendChild(option);
        });

      } else if (xhr.status !== 200) {
        alert("Request failed.  Returned status of " + xhr.status);
      }
    };

    xhr.send(`nombreCalle=${nombreVia.value}`);
  }
}

//Ejecuto la funcion cuando cambia el input de id "valorNombreVia"

document.getElementById("valorNombreVia").addEventListener("input", cargaNumerosDeVia);
