/* Hace una petición al servidor cuando se pulsa enviar y hay una referencia catastral completada */

export default function coordenadasPorReferenciaCatastral (){
    return new Promise((resolve, reject) => {
      const referenciaCatastral = document.getElementById("valorReferenciaCatastral");
      let coordenadas = [];
  
      if (referenciaCatastral.value != "") {
        const xhr = new XMLHttpRequest();
        xhr.open("POST", "./Request/enc/referenciaCatastralACoordenadasCifrado.php");
        xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
        xhr.onload = function () {
          if (xhr.status === 200) {
            const respuesta = JSON.parse(xhr.responseText);
            console.log(respuesta);
  
            //Si la respuesta es correcta, muestro las coordenadas
            if (respuesta) {
              coordenadas.push(respuesta[0].coordenadaX);
              coordenadas.push(respuesta[0].coordenadaY);
              resolve(coordenadas); // Resuelve la promesa con el array `coordenadas`
            }
            //Si no, muestro el error
            else {
              reject("No se ha encontrado la referencia catastral");
            }
          } else if (xhr.status !== 200) {
            reject("Request failed.  Returned status of " + xhr.status);
          }
        };
        console.log(referenciaCatastral.value);
        xhr.send(`referenciaCatastral=${referenciaCatastral.value}`);
      } else {
        reject("No se ha proporcionado una referencia catastral");
      }
    });
  };