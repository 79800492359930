// Funcion asincrona que carga las coordenadas de la base de datos de la calle seleccionada
export default async function cargaPuntosCallePorVia (nombreCalle) {
    let calle = nombreCalle;

    return new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();
        xhr.open("POST", "./Request/enc/cargaPuntosCallePorViaCifrado.php");
        xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
        xhr.onload = function () {
            if (xhr.status === 200) {
                const respuesta = JSON.parse(xhr.responseText);
                resolve(respuesta);
            }
            else {
                reject(new Error("Request failed. Returned status of " + xhr.status));
            }
        };
        //Separo el nombre de calle del tipo de via
        const nombreCalleArray = nombreCalle.split(",");
        const tipoVia = nombreCalleArray[0];
        calle = nombreCalleArray[1].trimStart();

        xhr.send(`tipoVia=${tipoVia}&nombreCalle=${calle}`);
    });
}
