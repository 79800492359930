// Funcion asincrona que carga las coordenadas de la base de datos de la calle seleccionada
export default async function cargaPuntosCalleSeleccionada (referenciaCatastral) {
    return new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();
        xhr.open("POST", "./Request/enc/cargaPuntosCalleSeleccionadaCifrado.php");
        xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
        xhr.onload = function () {
            if (xhr.status === 200) {
                const respuesta = JSON.parse(xhr.responseText);
                resolve(respuesta);
            }
            else {
                reject(new Error("Request failed. Returned status of " + xhr.status));
            }
        };
        xhr.send(`referenciaCatastral=${referenciaCatastral}`);
    });
}
