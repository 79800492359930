export default function cargaTipoViaPorReferenciaCatastral(referenciaCatastral) {
    return new Promise((resolve, reject) => {
      const referenciaCatastral = document.getElementById("valorReferenciaCatastral").value;
  
      const xhr = new XMLHttpRequest();
      xhr.open(
        "POST",
        "./Request/enc/cargaViaPorReferenciaCatastralCifrado.php"
      );
      xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
  
      xhr.onload = function () {
        if (xhr.status === 200) {
          const respuesta = JSON.parse(xhr.responseText);
          resolve(respuesta); // Return the response content
        } else if (xhr.status !== 200) {
          alert("Request failed.  Returned status of " + xhr.status);
        }
      }
  
      xhr.send(
        `referenciaCatastral=${referenciaCatastral}`
      );
    });
  }
  