//Funcion asincrona que devuelve la referencia catastral a partir de los datos de la via
export default async function cargaReferenciaCatastralPorVia() {
    return new Promise((resolve, reject) => {
        let nombreCalle = document.getElementById("valorNombreVia");
        let numeroCalle = document.getElementById("valorNumeroVias");
        let tipoVia = "";
        let letraCalle = "";
        let referenciaCatastral = "";

        //Separo el numero de calle de la letra, poniendo todas la letras en la parte de letraCalle y los numeros en la parte de numeroCalle
        if (numeroCalle.value != "") {
            const numeroCalleArray = numeroCalle.value.split(/(\d+)/).filter(Boolean);
            numeroCalle = numeroCalleArray[0];
            letraCalle = numeroCalleArray[1];
        }

        //Separo el tipo de via de la via
        if (nombreCalle.value != "") {
            const nombreCalleArray = nombreCalle.value.split(",");
            tipoVia = nombreCalleArray[0];
            nombreCalle = nombreCalleArray[1].trim();
        }

    

        if (nombreCalle.value != "" && numeroCalle.value != "") {
            const xhr = new XMLHttpRequest();
            xhr.open(
                "POST",
                "./Request/enc/cargaReferenciaCatastralPorViaCifrado.php"
            );
            xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
            xhr.onload = function () {
                if (xhr.status === 200) {
                    const respuesta = JSON.parse(xhr.responseText);

                    //Si la respuesta es correcta, muestro la referencia catastral
                    if (respuesta) {
                        referenciaCatastral = respuesta[0].referenciaCatastral1 + respuesta[0].referenciaCatastral2;
                        resolve(referenciaCatastral); // Resuelve la promesa con la referencia catastral
                    }
                    //Si no, muestro el error
                    else {
                        reject("No se ha encontrado la referencia catastral");
                    }
                } else if (xhr.status !== 200) {
                    reject("Request failed.  Returned status of " + xhr.status);
                }
            };
            if (letraCalle !== undefined && letraCalle !== "" && letraCalle !== " " && letraCalle !== null) {
                xhr.send(
                    `nombreCalle=${nombreCalle}&numeroCalle=${numeroCalle}&tipoVia=${tipoVia}&letraCalle=${letraCalle}`
                    );
                } else {
                    xhr.send(
                    `nombreCalle=${nombreCalle}&numeroCalle=${numeroCalle}&tipoVia=${tipoVia}`
                );
            }
        } else {
            reject("No se ha proporcionado una referencia catastral");
        }
    });
};






/* //Funcion asincrona que devuelve la referencia catastral a partir de los datos de la via
export default async function cargaReferenciaCatastralPorVia() {
    return new Promise((resolve, reject) => {
        const nombreCalle = document.getElementById("valorNombreVia");
        const numeroCalle = document.getElementById("valorNumeroVias");
        const tipoVia = document.getElementById("valorTipoVia");
        let referenciaCatastral = "";

        console.log(nombreCalle.value);
        console.log(numeroCalle.value);

        if (nombreCalle.value != "" && numeroCalle.value != "" && tipoVia.value != "") {
            const xhr = new XMLHttpRequest();
            xhr.open(
                "POST",
                "http://callejero.moose-software.com/Request/cargaReferenciaCatastralPorVia.php"
            );
            xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
            xhr.onload = function () {
                if (xhr.status === 200) {
                    const respuesta = JSON.parse(xhr.responseText);

                    //Si la respuesta es correcta, muestro la referencia catastral
                    if (respuesta) {
                        referenciaCatastral = respuesta[0].referenciaCatastral1 + respuesta[0].referenciaCatastral2;
                        resolve(referenciaCatastral); // Resuelve la promesa con la referencia catastral
                    }
                    //Si no, muestro el error
                    else {
                        reject("No se ha encontrado la referencia catastral");
                    }
                } else if (xhr.status !== 200) {
                    reject("Request failed.  Returned status of " + xhr.status);
                }
            };

            xhr.send(
                `nombreCalle=${nombreCalle.value}&numeroCalle=${numeroCalle.value}&tipoVia=${tipoVia.value}`
            );
        } else {
            reject("No se ha proporcionado una referencia catastral");
        }
    });
}; */

